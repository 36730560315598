import React from "react"
import styled from "styled-components"
import { styles, Text } from "./styles"

const Wrapper = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
`;

const Author = styled.a`
  color: ${styles.colorPrimary};
  text-decoration: none;
  transition: color .3s ease;
  
  &:hover {
    color: #000;
  }
`;

const Footer = () => {

    return (
        <Wrapper>
        <Text align={"center"}>
          © {new Date().getFullYear()}, ALL RIGHTS RESERVED. DESIGNED & CODED BY 
          {` `}
          <Author href="https://www.facebook.com/webscris" target="_blank" rel="noreferrer">CRIS</Author>
        </Text>
      </Wrapper>
    )
}

export default Footer