import React from "react"
import styled from "styled-components"
import FacebookIcon from "../images/facebook.inline.svg"
import InstagramIcon from "../images/instagram.inline.svg"

const Social = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    max-height: 20px;
    max-width: 20px;

    svg {
        path {
            fill: ${props => (props.color ? props.color : '#fff')};
            transition: fill .3s ease;
        }
    }

    &:hover {
        svg {
            path {
                fill: #000;
            }
        }
    }
`;

const SocialIcon = ({className, media, to, color}) => {

    return (
        <Social className={className} href={to} target="_blank" rel="noreferrer" color={color}>
            {resolveMedia(media)}
        </Social>
    )
}

export default SocialIcon

const resolveMedia = (media) => {
    if (media === "facebook") {
        return <FacebookIcon />
    } else if (media === "instagram") {
        return <InstagramIcon />
    } else {
        return null;
    }
}