import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby"
import color from "./color";

export const styles = {
  colorPrimary: '#79768B',
  //colorPrimary: color,
  colorBlack: '#363533',
  colorGray: '#79768B',
};

// Layout

export const SectionA = styled.section`
  display: ${props => (props.display ? props.display : 'block')};
  padding: ${props => margin(props.p ? props.p : '26px 0')};

  @media (min-width: 576px) {
    display: ${props => (props.displaySM ? props.displaySM : props.display)};
    padding: ${props => margin(props.pSM ? props.pSM : '68px 0')};
  }

  @media (min-width: 992px) {
    display: ${props => (props.displayLG ? props.displayLG : props.displaySM)};
    padding: ${props => margin(props.pLG ? props.pLG : '80px 0')};
  }

  @media (min-width: 1200px) {
    display: ${props => (props.displayXL ? props.displayXL : props.displayLG)};
    padding: ${props => margin(props.pXL ? props.pXL : '110px 0')};
  }
`;

export const SectionB = styled.section`
  position: relative;

  .MuiContainer-root {
    padding: 0;

    @media (min-width: 576px) {
      padding: 0 16px;
    }
  }
`;

export const FlexBox = styled.div`
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
  justify-content: ${props => (props.justify ? props.justify : 'flex-start')};
  align-items: ${props => (props.align ? props.align : 'flex-start')};
  padding: ${props => margin(props.p ? props.p : '0')};

  @media (min-width: 992px) {
    flex-direction: ${props => (props.directionLG ? props.directionLG : props.direction)};
    justify-content: ${props => (props.justifyLG ? props.justifyLG : props.justify)};
    align-items: ${props => (props.alignLG ? props.alignLG : props.align)};
    padding: ${props => margin(props.pLG ? props.pLG : props.p)};
  }
`

// Typography

export const Title1 = styled.h1`
  display: flex;
  flex-direction: column;
  color: ${styles.colorBlack};
  visibility: inherit;
  letter-spacing: 1px;
  font-size: 25px;
  font-weight: normal;
  line-height: 1.1666;
  font-family: 'Oswald', sans-serif;
  text-transform: uppercase;
  -webkit-font-feature-settings: 'liga';
  font-feature-settings: 'liga';

  text-align: ${props => (props.align ? props.align : 'center')};
  color: ${props => (props.color ? props.color : '#000')};
  margin-bottom: ${props => margin(props.mb ? props.mb : '0')};

  @media (min-width: 576px) {
    flex-direction: row;
  }

  @media (min-width: 1366px) {
    font-size: 2vw;
  }

  @media (min-width: 1920px) {
    font-size: 37px;
  }
`

export const Title2 = styled.h2`
  font-size: 30px;
  line-height: 37px;
  font-weight: 600;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;
  margin-top: 0;

  text-align: ${props => (props.align ? props.align : 'center')};
  color: ${props => (props.color ? props.color : styles.colorPrimary)};
  margin-bottom: ${props => margin(props.mb ? props.mb : '0')};
  padding: ${props => margin(props.p ? props.p : '0')};
`;

export const Title3 = styled.h3`
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  text-transform: uppercase;
  font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off;
  margin-top: 0;

  text-align: ${props => (props.align ? props.align : 'center')};
  color: ${props => (props.color ? props.color : styles.colorPrimary)};
  margin-bottom: ${props => margin(props.mb ? props.mb : '0')};
  padding: ${props => margin(props.p ? props.p : '0')};
`

export const Text = styled.p`
  font-size: 12px;
  line-height: 27px;
  margin-top: 0;

  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')};
  text-align: ${props => (props.align ? props.align : 'left')};
  color: ${props => (props.color ? props.color : '#000')};
  margin-bottom: ${props => margin(props.mb ? props.mb : '0')};
  padding: ${props => margin(props.p ? props.p : '0')};

  @media (min-width: 992px) {
    max-width: ${props => (props.maxWidthLG ? props.maxWidthLG : props.maxWidth)};
    text-align: ${props => (props.alignLG ? props.alignLG : props.align)};
    padding: ${props => margin(props.pLG ? props.pLG : props.p)};
  }
`

export const Link = styled(GatsbyLink)`
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  text-decoration: none;
  transition: color .3s ease;

  color: ${props => (props.color ? props.color : styles.colorPrimary)};
  margin-bottom: ${props => margin(props.mb ? props.mb : '0')};
  
  &:hover {
    color: #000;
  }
`;

//

const margin = (margin) => {
  if (typeof margin === 'string') {
    return margin;
  } else if (typeof margin === 'number') {
    if (margin === 1) {
      return '3px'
    } else if (margin === 2) {
      return '8px'
    } else if (margin === 3) {
      return '15px'
    } else if (margin === 4) {
      return '30px'
    } else if (margin === 5) {
      return '48px'
    } else if (margin === 6) {
      return '72px'
    } else {
      return '0'
    }
  } else {
    return '0'
  }
}