import React from "react";
import styled from "styled-components";
import Image from "../components/image";
import Container from "../components/container";
import { styles } from "./styles";
//import Menu from "./menu";

const Menu = React.lazy(() => import('./menu'));

const Wrapper = styled.header`
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    padding: 21px 0;

    &.alt {
        background-color: #fff;
        
        .logo {
            .gatsby-image-wrapper {
                &:nth-child(1) {
                    display: none;
                }
                &:nth-child(2) {
                    display: block;
                }
            }
        }

        .menu {
            a {
                svg {
                    path {
                        fill: ${styles.colorPrimary};
                    }
                }

                &:hover {
                    svg {
                        path {
                            fill: #000;
                        }
                    }
                }
            }
    
            button {
                span {
                    background-color: ${styles.colorPrimary};
                }

                &:hover {
                    span {
                        background-color: #000;
                    }
                }
            }

            ul {
                li {
                    a {
                        color: #fff;
                        background-color: ${styles.colorPrimary};
                        border-color: ${styles.colorPrimary};

                        &:hover {
                            color: ${styles.colorPrimary};
                            background-color: #fff;
                        }
                    }
                }
            }
        }
    }
  `;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    height: 50px;
    width: 100%;
`;

const Content = styled.div`
    width: 100%;
    z-index: 12;
`;

const Logo = styled.div`
    width: 130px;
    width: 45vw;
    max-width: 270px;

    .gatsby-image-wrapper {
        &:nth-child(1) {
            display: block;
        }
        &:nth-child(2) {
            display: none;
        }
    }
`;

const Flex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Header = (data) => {
    const isSSR = typeof window === "undefined"
    return (
        <Wrapper className={"header"}>
            <Content>
                <Container>
                    <Flex>
                        <LogoContainer>
                            <Logo className={"logo"}>
                                <Image filename={"logo.png"} />
                                <Image filename={"logo-color.png"} /> 
                            </Logo>
                        </LogoContainer>
                        {!isSSR && (
                            <React.Suspense fallback={<div />}>
                                <Menu facebook={data.data.oNasAdresFacebooka} instagram={data.data.oNasAdresInstagrama} />
                            </React.Suspense>
                        )}
                    </Flex>
                </Container>
            </Content>
        </Wrapper>
    )
}

export default Header
