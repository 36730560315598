import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const Wrapper = styled.div`
  display: ${props => props.flex ? "flex" : ""};
  flex-direction: ${props => props.column ? "column" : ""};
  justify-content: ${props => props.justify ? props.justify : ""};
  align-items: ${props => props.align ? props.align : ""};
  position: ${props => props.position ? props.position : "relative"};
  width: 100%;
  max-width: 568px;
  //   padding-left: 30px;
  //   padding-right: 30px;
  padding-left: ${props => props.noPadding ? "" : "15px"};
  padding-right: ${props => props.noPadding ? "" : "15px"};
  margin: 0 auto;

  @media (min-width: 600px) {
    max-width: 90%;
    padding-left: 24px;
    padding-right: 24px;
  }   

  @media (min-width: 1366px) {
    max-width: 1200px;
    padding-left: 32px;
    padding-right: 32px;
  }
`

const Container = ({ flex, column, directionMD, justify, align, children, style, width, className, id, position, noPadding}) => (
  <Wrapper style={style} flex={flex} column={column} directionMD={directionMD} justify={justify} align={align} width={width} className={className} id={id} position={position} noPadding={noPadding}>
    {children}
  </Wrapper>
)

export default Container

Container.propTypes = {
  width: PropTypes.number,
}

Container.defaultProps = {
  width: 1270,
}
